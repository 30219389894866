import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import Toast from "@/components/Modal/Toast"
const userIsLoggedIn = store.getters["auth/getLoginStatus"];

// const state = store.getters["auth/getState"];
const auth = getAuth();
function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (response) => {
        // response
        if (response) {
          response.auth.currentUser
            .getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              let userIsAdmin = {
                isAdmin: "",
              };

              if (idTokenResult.claims.role == "admin") {
                // Show admin UI.
                userIsAdmin["isAdmin"] = true;
                store.commit("auth/setCustomClaims", userIsAdmin);
              } else {
                // Show regular userIsLoggedIn UI.
                userIsAdmin["isAdmin"] = false;
                store.commit("auth/setCustomClaims", userIsAdmin);
                console.log(
                  "idtoken result: ",
                  idTokenResult.claims.role == "admin"
                );
                // console.log(userIsLoggedIn, idTokenResult.claims.admin, state);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        unsubscribe();
        resolve(response);
      },
      reject
    );
  });
}

const routes = [
  {
    path: "/resource/:token",
    name: "sharedPage",
    component: () => import("../views/SharedPage.vue"),
  },

  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/layouts/FilesDashboard.vue"),

    // component: () => import("@/layouts/AccountsDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "manage-account",
        path: "/manage-account/:accountId",
        component: () => import("@/views/Admin/ManageSingleAccount.vue"),
      },
      {
        path: "/manage-accounts/manage-account-projects/:accountId",
        name: "manage-account-projects",
        component: () =>
          import("@/views/AccountManagement/ManageAccountProjects.vue"),
      },

      {
        name: "manage-account-users",
        path: "/manage-accounts/:accountId/manage-users",
        component: () =>
          import("@/views/AccountManagement/ManageAccountUsers.vue"),
      },
    ],
  },
  {
    path: "/accounts-projects/:accountId",
    name: "account-projects-homepage",
    component: () => import("@/layouts/ProjectsDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    name: "projectsCDE",
    path: "/:accountId/:projectId/ProjectCDE",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layouts/FilesDashboard.vue"),
    children: [
      {
        path: "/:accountId/:projectId/ProjectCDE/folders/:folderId",
        name: "CDE-files",
        component: () =>
          import("@/components/folderManagement/DataManagementTable.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: () => import("@/layouts/AdminLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "adminHome",
        path: "/dashboard",
        component: () => import("@/views/AdminView.vue"),
      },
      {
        name: "add-user-page",
        path: "/project/manage-users",
        component: () => import("@/views/Admin/ManageUsers.vue"),
      },
      {
        path: "/admin/manage-projects",
        name: "manage-projects",
        component: () => import("@/views/Admin/ManageProjects.vue"),
      },
    ],
  },

  {
    path: "/projects/:project_id/CDE",
    name: "modelsData",
    component: () => import("@/views/model_views/modelViewCDE.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/profileForm"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/accounts",
    name: "choose_account",
    component: () => import("@/components/profileForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:project_id/viewer_data",
    name: "ViewerData",
    component: () => import("@/views/model_views/modelViewerData.vue"),
  },

  {
    path: "/projects/:project_id/model_view",
    name: "projectModels",
    component: () => import("@/views/ModelsHomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/FilesDashboard.vue"),
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "manage-accounts",
        path: "/",
        component: () => import("@/views/Admin/ManageAccounts.vue"),
      },
    ],
  },
  // {
  //   path: "/projects/:project_id/viewer_data",
  //   name: "projectModels",
  //   component: () => import("@/views/ModelsHomeView.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/projects/:project_id/table_view",
  //   name: "modelsData",
  //   component: () => import("@/views/model_views/modelViewCDE.vue"),
  // },
  // {
  //   path: "/projects/:project_id/viewer_data",
  //   name: "ViewerData",
  //   component: () => import("@/views/model_views/modelViewerData.vue"),
  // },

  {
    path: "/password_reset",
    name: "resetPassword",
    component: () => import("@/components/ForgotPassword.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),

    beforeEnter: (to, from, next) => {
      if (userIsLoggedIn.loggedIn) {
        return next("/");
      }
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    if (!(await getCurrentUser())) {
      next("/login"); // Redirect to the login page if not authenticated
    } else {
      console.log(await getCurrentUser());
      next(); // Proceed to the requested route if authenticated
    }
  } else {
    next(); // No authentication required, proceed to the requested route
  }
});

export default router;
