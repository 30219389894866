import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { auth } from "../firebase/config.js";
import store from "@/store";

import { updateProfile } from "firebase/auth";

onAuthStateChanged(auth, async (user) => {
  if (user) {
    store.commit("auth/loginSuccess", user);
  } else {
    store.commit("auth/logout", user);
    console.log(user, " User is signed out");
  }
});

class AuthService {
  async login({ email, password }) {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredentials.user;
      // let userObj = {};

      // user.auth.currentUser
      //   .getIdTokenResult()
      //   .then((idTokenResult) => {
      //     // Confirm the user is an Admin.
      //     if (!idTokenResult.claims.role === "admin") {
      //       // Show admin UI.
      //       // showAdminUI();
      //       console.log(idTokenResult.claims);
      //     } else {
      //       // Show regula r user UI.
      //       // showRegularUI();
      //       userObj["adminStatus"] = idTokenResult.claims;
      //       userObj["user"] = user;
      //       console.log(userObj);
      //       // commit("setRoles", idTokenResult.claims);
      //       localStorage.setItem("user", JSON.stringify(userObj));
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  logout() {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful.");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  }

  async updateUserProfile({ displayName, phoneNumber }) {
    updateProfile(auth.currentUser, {
      displayName,
      phoneNumber,
      photoURL:
        "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80",
    })
      .then(() => {
        // Profile updated!
        const user = auth.currentUser.providerData[0];
        localStorage.setItem("user", JSON.stringify(user));
        console.log("Profile updated");
        return user;
      })
      .catch((error) => {
        // An error occurred
        console.error(error);
      });
    // try {
    //   const resp = await updateProfile(user.currentUser, {
    //     displayName,
    //     photoURL: "https://example.com/jane-q-user/profile.jpg",
    //     phoneNumber,
    //   });
    //   console.log(resp);
    // } catch (error) {
    //   console.error(error);
    // }
  }
  // register(user) {
  //   return axios
  //     .post(
  //       API_URL + "auth/signup",
  //       {
  //         last_name: user.lastName,
  //         first_name: user.firstName,
  //         email: user.email,
  //         password: user.password,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           // remove headers
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data) {
  //         localStorage.setItem("user", JSON.stringify(response.data));
  //       }

  //       console.log(response.data);
  //       return response.data;
  //     });
  // }
}

export default new AuthService();
