import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";

// theme
// import "primevue/resources/themes/md-light-indigo/theme.css";

// import "primeicons/primeicons.css";

// import PrimeVue from "primevue/config";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from "primevue/toastservice";
// import Lara from "./presets/lara";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
createApp(App)
  .use(store)
  .component("fa", FontAwesomeIcon)
  .use(router)
  .use(ToastService)
  .use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        darkModeSelector: ".my-app-dark",
      },
    },
  })
  .mount("#app");
