import store from "@/store";

export default function authHeader() {
  const userIsLoggedIn = store.getters["auth/getLoginStatus"];
  const user = store.getters["auth/getCurrentUser"];

  if (userIsLoggedIn) {
    return {
      Authorization: "Bearer " + user.accessToken,
    };
  } else {
    return {};
  }
}
