import { createStore } from "vuex";
// import FetchData from "@/services/fetch.service";
import { onSnapshot } from "firebase/firestore";

import { auth } from "./auth.module";
import { userService } from "./user.module";
import { config } from "./user-settings.module";

export default createStore({
  state() {
    return {
      models: [],
      projects: [],
    };
  },
  mutations: {
    setModels(state, payload) {
      state.models = payload;
      console.log("user state changed: ", state.models);
    },
  },
  actions: {
    async setModelsData({ commit }, query) {
      const modelsData = [];
      try {
        onSnapshot(query, (snapshot) => {
          snapshot.forEach((doc) => {
            let modelObject = {
              id: doc.id,
              ...doc.data(),
              selected: false,
              isChecked: false,
              isHighlighted: false,
            };

            if (modelObject.isSystemModel) {
              modelObject["selected"] = true;
              modelsData.push(modelObject);
            } else {
              modelsData.push(modelObject);
            }
          });

          commit("setModels", modelsData);
        });
      } catch (error) {
        console.error("Error retrieving reports:", error);
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getModelsData: function (state) {
      return state.models;
    },
  },

  modules: { auth, config, userService },
});
