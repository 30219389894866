import UserService from "@/services/user.service";
// import router from "@/router";

const userService = {
  namespaced: true,
  state() {
    return {
      users: [],
    };
  },
  actions: {
    async fetchAllUsers({ commit }) {
      try {
        let users = await UserService.getAllUsers();
        commit("setUsers", users.data.users);
      } catch (error) {
        console.error(error);
      }
    },

    async createUser({ commit }, userDetails) {
      try {
        commit;
        await UserService.createUser(userDetails);
      } catch (error) {
        console.error(error);
      }
    },

    // async updateProfile({ commit }, userDetails) {
    //   return AuthService.updateUserProfile(userDetails).then((user) => {
    //     commit("updateUserSuccess", userDetail

    //     router.push("/admin/dashboard");

    //     return Promise.resolve(user);
    //   });
    // },
    // logout({ commit }) {
    //   const resp = AuthService.logout();
    //   console.log(resp);
    //   router.push({ name: "login" });
    //   commit("logout");
    // },
    // register({ commit }, user) {
    //   return AuthService.register(user).then(
    //     (response) => {
    //       commit("registerSuccess");
    //       return Promise.resolve(response.data);
    //     },
    //     (error) => {
    //       commit("registerFailure");
    //       return Promise.reject(error);
    //     }
    //   );
    // },
  },

  mutations: {
    // loginSuccess(state, user) {
    //   state.status.loggedIn = true;
    //   state.user = user;
    // },

    setUsers(state, users) {
      state.users = users;
    },

    // loginFailure(state) {
    //   state.status.loggedIn = false;
    //   state.user = null;
    // },
    updateUserSuccess(state) {
      state.status.loggedIn = true;
      //   state.user = user;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = true;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    getUsers(state) {
      let users = state.users;
      return users;
    },

    // getCustomClaims(initialState) {
    //   return initialState.customClaims;
    // },

    // getState(initialState) {
    //   return initialState;
    // },
    // getLoginStatus(initialState) {
    //   return initialState.status;
    // },
    // checkIfUserIsAdmin(initialState) {
    //   let isUserAdmin;

    //   try {
    //     if (initialState.user.adminStatus.role) {
    //       isUserAdmin =
    //         initialState.user.adminStatus.role === "admin" ? true : false;
    //     } else {
    //       isUserAdmin = false;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   return isUserAdmin;
    // },
    getRoles(initialState) {
      let roles = initialState.user;
      return roles;
    },
  },
};

// const unsubscribe = store.subscribe((mutation, state) => {
//   console.log(mutation.type);
//   console.log(mutation.payload);
// });

// unsubscribe();
export { userService };
