import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore /*connectFirestoreEmulator*/ } from "firebase/firestore";
import { getAuth /*0connectAuthEmulator*/ } from "firebase/auth";
import { getStorage /* connectStorageEmulator*/ } from "firebase/storage";
import { getFunctions /*connectFunctionsEmulator*/ } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyClM8eef7EzeENc1cxhCIyi6EtGJb8NrZI",
  authDomain: "pamo-digital-376fe.firebaseapp.com",
  projectId: "pamo-digital-376fe",
  storageBucket: "pamo-digital-376fe.appspot.com",
  messagingSenderId: "336270802431",
  appId: "1:336270802431:web:06e522d1a9d2f02ede3bca",
  measurementId: "G-B79CRSZS0F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
// connectFirestoreEmulator(db, "127.0.0.1:8080");
const storage = getStorage(app);
// connectStorageEmulator(storage, "127.0.0.1:9199");
const auth = getAuth(app);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "127.0.0.1:5001");

export { auth, analytics, db, storage, functions };
