export const config = {
  namespaced: true,
  state: {
    levels: {},
    spaces: {},
    activeModels: [],
  },

  mutations: {
    updateSpaces(state, data) {
      state.spaces = data;
    },
    updateLevels(state, data) {
      state.levels = data.levels;
    },
    setActiveModels(state, data) {
      console.log(data);
      state.activeModels.push(data);
    },
  },

  actions: {
    async setLevels({ commit }, data) {
      commit("updateLevels", data);
    },
    async setSpaces({ commit }, data) {
      console.log(data);

      commit("updateSpaces", data);
    },

    async setActiveModels({ commit }, data) {
      commit("setActiveModels", data);
    },
  },

  getters: {
    getSpaces(state) {
      return state.spaces;
    },
    getLevels(state) {
      return state.levels;
    },
    getActiveModel(state) {
      return state.activeModels[0];
    },
  },
};
